@keyframes glitch {
  0% {
    text-shadow: 2px 2px #ff00ff, -2px -2px #00ffff;
  }
  20% {
    text-shadow: -2px -2px #ff00ff, 2px 2px #00ffff;
  }
  40% {
    text-shadow: 2px -2px #ff00ff, -2px 2px #00ffff;
  }
  60% {
    text-shadow: -2px 2px #ff00ff, 2px -2px #00ffff;
  }
  80% {
    text-shadow: 2px 2px #ff00ff, -2px -2px #00ffff;
  }
  100% {
    text-shadow: -2px -2px #ff00ff, 2px 2px #00ffff;
  }
}

.App {
  text-align: center;
  background-color: #000000; /* Pure black background */
  color: #00ff00; /* Neon green text */
  font-family: 'Courier New', Courier, monospace; /* Monospaced font */
}

.App-header {
  background-color: #1a1a1a; /* Darker background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ff00ff; /* Neon purple text */
  animation: glitch 1s infinite; /* Glitch animation */
}

.mainText {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Space between input and button group */
}

.button-group {
  display: flex;
  gap: 10px; /* Space between buttons */
}

.input {
  padding: 12px 20px;
  border: 1px solid #ff00ff; /* Neon purple border */
  border-radius: 5px;
  font-size: 1em;
  text-align: center;
  background-color: #333; /* Darker input background */
  color: #ff00ff; /* Neon purple text */
  box-shadow: 0 0 5px #ff00ff; /* Neon purple glow */
  width: 100%; /* Full width of the container */
  max-width: 400px; /* Maximum width to prevent excessive stretching */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  transition: box-shadow 0.3s, border 0.3s; /* Smooth transitions on focus */
}

.input:focus {
  border: 1px solid #ffffff; /* White border on focus */
  box-shadow: 0 0 10px #ff00ff; /* Enhanced glow on focus */
  outline: none; /* Remove default outline */
}

.button {
  padding: 10px 20px;
  border: 1px solid #ff00ff; /* Neon purple border */
  border-radius: 5px;
  font-size: 1em;
  text-align: center;
  background: linear-gradient(145deg, #1a1a1a, #333); /* Subtle gradient */
  color: #ff00ff; /* Neon purple text */
  box-shadow: 0 0 5px #ff00ff; /* Neon purple glow */
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.button:hover {
  background: linear-gradient(145deg, #333, #1a1a1a); /* Inverted gradient on hover */
  box-shadow: 0 0 10px #ff00ff; /* Increased glow on hover */
}

.button:active {
  background: #000; /* Darker background on click */
  box-shadow: 0 0 2px #ff00ff; /* Reduced glow on click */
}

.music-button {
  padding: 10px 20px;
  border: 1px solid #ff00ff; /* Neon purple border */
  border-radius: 5px;
  font-size: 1em;
  text-align: center;
  background: linear-gradient(145deg, #1a1a1a, #333); /* Subtle gradient */
  color: #ff00ff; /* Neon purple text */
  box-shadow: 0 0 5px #ff00ff; /* Neon purple glow */
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.music-button:hover {
  background: linear-gradient(145deg, #333, #1a1a1a); /* Inverted gradient on hover */
  box-shadow: 0 0 10px #ff00ff; /* Increased glow on hover */
}

.music-button:active {
  background: #000; /* Darker background on click */
  box-shadow: 0 0 2px #ff00ff; /* Reduced glow on click */
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
